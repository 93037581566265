import shopifyGenericDefaults from '../shopify-dawn-generic/config.js';

globalThis.Convermax.fitmentFieldsTranslation = {
  Make: 'Hersteller',
  Model: 'Modell',
  Submodel: 'Serie',
};

const { ignoreFields } = shopifyGenericDefaults.Widgets.find((w) => w.name === 'Facets');

shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBox').location =
  '.FixedHeaderSearch--desktop .search-modal__content';
shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBoxDialogButton').location =
  '.FixedHeaderSearch--mobile .search-modal__content';
shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBoxDialogButton').template =
  'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt';
shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchHeader').sortEntries = {
  'relevance': 'Beste Übereinstimmung',
  'title': 'Von, A-Z',
  'title:desc': 'Von, Z-A',
  'collection_sort': 'Hervorgehoben',
  'price': 'Preis, Niedrig bis Hoch',
  'price:desc': 'Preis, Hoch to Niedrig',
  'published_at': 'Datum, Von Alt zu Neu',
  'published_at:desc': 'Datum, Neu zu alt',
};
shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchVehicleWidget').columnBreakpoint = 600;
shopifyGenericDefaults.Widgets.find((w) => w.name === 'VerifyFitment').columnBreakpoint = 600;

const genericUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
).updateCallback;

const updateCallback = () => {
  genericUpdateCallback?.();
  window.ExemptifyTriggerUpdate?.();
};

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyGenericDefaults,
  product: {
    ...shopifyGenericDefaults.product,
    searchResultsImageWidth: 500,
    searchResultsImageHeight: 500,
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    isExtraFieldsAlwaysVisible: true,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['Facets', 'SearchResult'].includes(w.name)),
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields: [...ignoreFields, 'Fitment'],
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
  ],
};
