export default {
  SEARCH_BOX_FORM_CLASS: 'field',
  SEARCH_BOX_INPUT_CLASS: 'search__input field__input',
  SEARCH_BOX_PLACEHOLDER: 'Ersatzteile-Nr. oder Suchbegriff eingeben',
  SEARCH_BOX_CLEAR_BUTTON: '{window.innerWidth > 768 ? "✕" : "Klar"}',
  SEARCH_BOX_SECTION_TITLE: `<b>{this.totalHits > 1 ? \`\${this.totalHits} \` : ''}{this.sectionTitle === 'Products' ? 'Produkte' : this.sectionTitle}</b>
    {!!this.vehicleString ? \` für \${this.vehicleString}\` : ''}`,
  BROWSE_ALL: 'Durchsuchen Sie alle {{totalHits}} Produkte',
  PRICE_FROM: `Ab:`,

  FILTERS_TEXT: 'Filter',
  FACET_DIALOG_TITLE: 'Filter',
  FACET_DIALOG_CLEAR: 'Löschen',
  FACET_DIALOG_DONE: 'Erledigt',
  FACET_DIALOG_RESULTS: 'Produkte',

  TOTAL_HITS: 'Produkte',
  SORT_BY: 'Sortiere nach&nbsp;',

  LOAD_MORE_TEXT: 'Nächste {{ nextPageSize }}',

  CURRENT_SEARCH: 'Aktuelle Suche:',
  MAX_PRICE: 'Max Preis',
  MIN_PRICE: 'Min Preis',
  SHOW_LESS: 'Weniger anzeigen',
  SHOW_MORE: 'Mehr anzeigen',
  START_OVER: 'Von vorne anfangen',
  FACET_FILTER_PLACEHOLDER: 'Geben',
  FACET_FILTER_NOTHING: 'Keine Einträge gefunden',

  YOU_MAY_ALSO_LIKE: 'Das könnte dir auch gefallen',

  FITMENT_TABLE_MANY_FITMENTS:
    'Derzeit werden 1000 Ausstattungen angezeigt, das Produkt verfügt jedoch über zusätzliche Ausstattungen.',

  SELECT_YOUR_VEHICLE: 'Modellsuche',
  SELECT_YOUR_VEHICLE_BLOCK: 'Modellsuche',
  ADD_VEHICLE: 'Fahrzeug hinzufügen',
  CLEAR_TEXT: 'Löschen',
  GO_TEXT: '{window.document.body.classList.contains("product") ? "Eingabe" : "Suchen"}',
  CHANGE_TEXT: 'Ändern',
  CHANGE_VEHICLE: 'Ändern',
  DISCARD_VEHICLE: 'Löschen',

  VERIFY_FITMENT_TITLE: 'Passt der Artikel auf meine Maschine?',
  VERIFY_FITMENT_FITS: 'Dieses Produkt ist passend für das Fahrzeug',
  VERIFY_FITMENT_NOT_FIT: 'Dieses Produkt ist nicht passend für das ausgewählte Fahrzeug',
  VERIFY_FITMENT_SPECIFY: 'Bitte angeben {{fieldsToSpecify.join(", ")}} für Ihre {{vehicleString}}',
  FITMENT_UNIVERSAL_TITLE: 'Universal Produkt',
  FITMENT_UNIVERSAL_TEXT: 'Passgenauigkeit der Artikelbeschreibung entnehmen',
  FITMENT_UNKNOWN_TITLE: 'Keine Einbaudaten',
  FITMENT_UNKNOWN_TEXT: '',
  VERIFY_FITMENT_VEHICLE: `{this.vehicleString || 'Fahrzeug'}`,
  VERIFY_FITMENT_COMPATIBLE_PARTS: 'Klicke hier um passende Teile zu finden für dein Fahrzeug',

  SELECT_TITLE: `{window.Convermax.fitmentFieldsTranslation[this.title.replaceAll(' ', '_')] || this.title}`,
  SELECT_LOADING: '...wird geladen...',

  CLEAR_GARAGE_TEXT: `Fahrzeug löschen`,
  GET_PRODUCTS_FOR_VEHICLE: `Passende Produkte für dein Fahrzeug`,
  FIND_PARTS_YOU_NEED: `Einfache suche `,
  STORE_VEHICLE_IN_GARAGE: `Fahrzeug in Garage speichern`,
};
