
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, fieldValue, fieldValueIndex) {
                    return [
                        _createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '1671'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } })),
                        this[field] && this[field] !== 'Universal' && field === 'Modell' ? _createElement('div', {
                            'className': 'fitment-cell__value fitment-cell__search',
                            'key': '1673'
                        }, fieldValue === 'Modell' ? _createElement('div', {
                            'className': 'fitment-cell',
                            'key': '613'
                        }, 'Search') : null, fieldValue !== 'Modell' ? _createElement('a', {
                            'href': '/pages/search?Make/' + this['Hersteller'] + '/Model/' + this['Modell'],
                            'key': '708'
                        }, '\n                View All Parts\n              ') : null) : null
                    ];
                }
                function repeatField3(field, fieldIndex) {
                    return _createElement.apply(this, [
                        'div',
                        {
                            'className': 'fitment-cell fitment-field-' + field.toLowerCase(),
                            'key': this[field]
                        },
                        _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex))
                    ]);
                }
                return _createElement.apply(this, [
                    'div',
                    { 'className': 'fitment-row' },
                    _map(this.fields, repeatField3.bind(this))
                ]);
            }, { count: undefined })];
    }
    function repeatItems2(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '3791'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function repeatField3(field, fieldIndex) {
                    return [
                        this[field] && this[field] !== 'Universal' ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'fitment-row',
                                'key': this[field]
                            },
                            _createElement('div', { 'className': 'fitment-cell' }, window.themeSettings?.locales?.ymm[field.toLowerCase()] || field),
                            _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex))
                        ]) : null,
                        this[field] && this[field] !== 'Universal' && field === 'Modell' ? _createElement('div', {
                            'className': 'fitment-row fitment-row__search',
                            'key': '852'
                        }, _createElement('div', { 'className': 'fitment-cell' }, 'Search'), this[field] !== 'Modell' ? _createElement('a', {
                            'className': 'fitment-cell__value',
                            'href': '/pages/search?Make/' + this['Hersteller'] + '/Model/' + this['Modell'],
                            'key': '908'
                        }, '\n              View All Parts\n            ') : null) : null
                    ];
                }
                return itemsIndex !== 0 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'fitment-table__inner-container',
                        'key': '7'
                    },
                    _map(this.fields, repeatField3.bind(this))
                ]) : null;
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_fitment-table fitment-table-container' }, this.vehicleSpecific ? _createElement('div', {
        'className': 'fitment-table-title vehicle-specific',
        'key': '58'
    }) : null, this.noFitmentData ? _createElement('div', {
        'className': 'fitment-table-title no-fitment-data',
        'key': '154'
    }, '\n    Keine Einbaudaten', _createElement('br', {})) : null, this.universalFit ? _createElement('div', {
        'className': 'fitment-table-title universal-fit',
        'key': '269'
    }, '\n    Universal Produkt', _createElement('br', {}), 'Passgenauigkeit der Artikelbeschreibung entnehmen\n  ') : null, this.manyFitments ? _createElement('div', {
        'className': 'fitment-table-title many-fitments',
        'key': '430'
    }, 'Derzeit werden 1000 Ausstattungen angezeigt, das Produkt verfügt jedoch über zusätzliche Ausstattungen.') : null, this.template === 'horizontal' ? _createElement('div', {
        'className': 'cm_fitment-table__horizontal cmTemplate_horizontal',
        'key': '630'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])) : null, this.template === 'vertical' ? _createElement('div', {
        'className': 'cm_fitment-table__vertical cmTemplate_vertical',
        'key': '2947'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems2.bind(this))
    ])) : null);
}
        export const componentNames = []